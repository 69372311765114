import './txTableTmn.scss';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import Featured from '../../components/featuredTmn/FeaturedTmn';

export default function TxTableTmn({
	recruteurs,
	missions,
	totalBesoins,
	totalCvEnvoyes,
	totalOnboardings,
}) {
	const [rows, setRows] = useState([]);
	const [cells, setCells] = useState([]);
	const [recruteursTmn, setRecruteursTmn] = useState([]);
	const [txProdTotal, setTxProdTotal] = useState(0);
	const [txSrvTotal, setTxSrvTotal] = useState(0);
	const [txTransfoTotal, setTxTransfoTotal] = useState(0);

	useEffect(() => {
		if (recruteurs) {
			const rRecru = Object.values(recruteurs).map(r => r._data);
			setCells(() => rRecru);
		}
	}, [recruteurs]);

	useEffect(() => {
		if (cells) {
			const newArr = [];
			for (const recruteur of cells) {
				recruteur.onboardings = 0;
				recruteur.cvEnvoyes = 0;
				recruteur.besoinsClients = 0;

				if (missions.length > 0) {
					for (const mission of missions) {
						if (mission.id_userRecruteur == recruteur.id) {
							recruteur.onboardings += parseInt(
								mission.nbOnBoardings
							);
							recruteur.cvEnvoyes += parseInt(mission.nbCvEnvoye);
							recruteur.besoinsClients +=
								parseInt(mission.nbInterimaires) || 0;
						}
					}
				}

				if (+recruteur.besoinsClients > 0) {
					recruteur.txProductivite = (
						(+recruteur.cvEnvoyes / +recruteur.besoinsClients) *
						100
					).toFixed(1);
					recruteur.txService = (
						(+recruteur.onboardings / +recruteur.besoinsClients) *
						100
					).toFixed(1);
				} else {
					recruteur.txProductivite = '-';
					recruteur.txService = '-';
				}

				if (recruteur.cvEnvoyes > 0) {
					recruteur.txTransformation = (
						(+recruteur.onboardings / +recruteur.cvEnvoyes) *
						100
					).toFixed(1);
				} else {
					recruteur.txTransformation = '-';
				}

				newArr.push(recruteur);
			}
			setRecruteursTmn(newArr);
		}
	}, [missions, cells]);

	useEffect(() => {
		let txProd = 0;
		let txSrv = 0;
		let txTransfo = 0;
		if (recruteursTmn.length) {
			for (const recruteur of recruteursTmn) {
				txProd += +recruteur.txProductivite || 0;
				txSrv += +recruteur.txService || 0;
				txTransfo += +recruteur.txTransformation || 0;
			}
			txProd = (txProd / recruteursTmn.length).toFixed(1);
			txSrv = (txSrv / recruteursTmn.length).toFixed(1);
			txTransfo = (txTransfo / recruteursTmn.length).toFixed(1);

			setTxProdTotal(() => +txProd);
			setTxSrvTotal(() => +txSrv);
			setTxTransfoTotal(() => +txTransfo);
		}
	}, [recruteursTmn]);

	return (
		recruteursTmn && (
			<TableContainer className='tableContainer px-3' component={Paper}>
				<Table
					className='text-center '
					sx={{ minWidth: 650 }}
					size='medium'
					aria-label='Taux Delivery'>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell className='colTitle'>
								Objectifs
							</TableCell>
							{cells &&
								cells.map(cell => (
									<TableCell
										className='recrutyNames'
										key={cell.id}>
										<div className='firstname'>
											{cell.prenom}
										</div>
										<div className='name'>{cell.nom}</div>
									</TableCell>
								))}
							<TableCell className='colTitle colTotal'>
								Total
							</TableCell>
						</TableRow>
					</TableHead>
					{recruteursTmn && (
						<TableBody>
							<TableRow>
								<TableCell className='rowChiffres'>
									Chiffres
								</TableCell>
								<TableCell></TableCell>
								{recruteursTmn.map(recruteur => (
									<TableCell
										className='chiffres'
										key={recruteur.id}>
										Besoins: {recruteur.besoinsClients}
										<br />
										Cv envoyés: {recruteur.cvEnvoyes}
										<br />
										Onboardings: {recruteur.onboardings}
									</TableCell>
								))}
								<TableCell className='chiffres colTotal'>
									Besoins: {totalBesoins}
									<br />
									Cv envoyés: {totalCvEnvoyes}
									<br />
									Onboardings: {totalOnboardings}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='rowTitle'>
									Tx de Productivité
									<br />
									<small>(CV envoyés / Besoins client)</small>
								</TableCell>
								<TableCell className='rowPercentage'>
									120%
								</TableCell>
								{recruteursTmn.map(recruteur => (
									<TableCell key={recruteur.id}>
										<Featured
											className='col-auto'
											objectif={120}
											// title='Taux de Productivité'
											// subTitle='nb CV envoyés / nb besoin client'
											besoinsClients={
												recruteur.besoinsClients
											}
											cvEnvoyes={recruteur.cvEnvoyes}
											txProductivite={
												recruteur.txProductivite
											}
										/>
									</TableCell>
								))}
								<TableCell className='colTotal'>
									<Featured
										className='col-auto'
										objectif={120}
										// title='Taux de Productivité'
										// subTitle='nb CV envoyés / nb besoin client'
										// besoinsClients={
										// 	recruteur.besoinsClients
										// }
										// cvEnvoyes={recruteur.cvEnvoyes}
										txProductivite={txProdTotal}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='rowTitle '>
									Tx de Transformation
									<br />
									<small>(OnBoarding / CV envoyés)</small>
								</TableCell>
								<TableCell className='rowPercentage'>
									90%
								</TableCell>
								{recruteursTmn.map(recruteur => (
									<TableCell key={recruteur.id}>
										<Featured
											className='col-auto'
											objectif={90}
											// title='Taux de Transformation'
											// subTitle='nb onBoarding / nb CV envoyés'
											onBoardings={recruteur.onboardings}
											cvEnvoyes={recruteur.cvEnvoyes}
											txTransformation={
												recruteur.txTransformation
											}
										/>
									</TableCell>
								))}
								<TableCell className='colTotal'>
									<Featured
										className='col-auto'
										objectif={90}
										// title='Taux de Productivité'
										// subTitle='nb CV envoyés / nb besoin client'
										// besoinsClients={
										// 	recruteur.besoinsClients
										// }
										// cvEnvoyes={recruteur.cvEnvoyes}
										txProductivite={txTransfoTotal}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='rowTitle'>
									Tx de Service
									<br />
									<small>
										(OnBoardings / Besoins client)
									</small>
								</TableCell>
								<TableCell className='rowPercentage'>
									80%
								</TableCell>
								{recruteursTmn.map(recruteur => (
									<TableCell key={recruteur.id}>
										<Featured
											className='col-auto'
											objectif={80}
											// title='Taux de Service'
											// subTitle='nb onBoarding / nb besoin client'
											onBoardings={recruteur.onboardings}
											besoinsClients={
												recruteur.besoinsClients
											}
											txService={recruteur.txService}
										/>
									</TableCell>
								))}
								<TableCell className='colTotal'>
									<Featured
										className='col-auto'
										objectif={80}
										// title='Taux de Productivité'
										// subTitle='nb CV envoyés / nb besoin client'
										// besoinsClients={
										// 	recruteur.besoinsClients
										// }
										// cvEnvoyes={recruteur.cvEnvoyes}
										txProductivite={txSrvTotal}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</TableContainer>
		)
	);
}
