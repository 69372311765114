import './loading.scss';
const Loading = () => {
	return (
		<div className='spinner-container'>
			<div className='loading-element'>
				<div className='loading-spinner'></div>
				<div className='loading-text'>Chargement...</div>
			</div>
		</div>
	);
};

export default Loading;
