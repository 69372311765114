import './login.scss';
import { useAuth0 } from '@auth0/auth0-react';
import imageDashboard from './dashboardImg.png';
const Login = () => {
	const { loginWithRedirect } = useAuth0();

	return (
		<section className='login'>
			<div className='loginContainer'>
				<div className='image-dash'>
					<img src={imageDashboard} alt='image-dashboard' />
				</div>

				<button
					onClick={loginWithRedirect}
					className='btn btn-login btn-primary'>
					Connexion
				</button>
			</div>
		</section>
	);
};

export default Login;
