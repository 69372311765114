import './widget.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useState } from 'react';
const Widget = ({ type, amount, diff }) => {
	let data;

	switch (type) {
		case 'user':
			data = {
				title: 'CLIENTS',
				isMoney: false,
				link: 'Voir tous les clients',
				icon: (
					<PersonIcon
						className='icon'
						style={{
							color: 'crimson',
							backgroundColor: 'rgba(255,0,0,0.2)',
						}}
					/>
				),
			};
			break;
		case 'earning':
			data = {
				title: 'REVENUS',
				isMoney: true,
				link: 'Voir le bénéfice net',
				icon: (
					<MonetizationOnIcon
						className='icon'
						style={{
							color: 'green',
							backgroundColor: 'rgba(0,128,0,0.2)',
						}}
					/>
				),
			};
			break;
		case 'order':
			data = {
				title: 'COMMANDES',
				isMoney: false,
				link: 'Voir toutes les commandes',
				icon: (
					<ShoppingCartIcon
						className='icon'
						style={{
							color: 'goldenrod',
							backgroundColor: 'rgba(218,165,32,0.2)',
						}}
					/>
				),
			};
			break;
		case 'balance':
			data = {
				title: 'SOLDE',
				isMoney: true,
				link: 'Voir le détail du solde',
				icon: (
					<AccountBalanceIcon
						className='icon'
						style={{
							color: 'purple',
							backgroundColor: 'rgba(128,0,128,0.2)',
						}}
					/>
				),
			};
			break;

		default:
			break;
	}

	return (
		<div className='widget'>
			<div className='left'>
				<span className='title'>{data.title}</span>
				<span className='counter'>
					{data.isMoney && '$'}
					{amount}
				</span>
				<span className='link'>{data.link}</span>
			</div>
			<div className='right'>
				<div className='percentage positive'>
					{diff ? (
						<>
							<KeyboardArrowUpIcon />
							{diff}%
						</>
					) : (
						'--'
					)}
				</div>
				{data.icon}
			</div>
		</div>
	);
};

Widget.defaultProps = {
	amount: 0,
};

export default Widget;
