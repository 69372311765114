import { useAuth0 } from '@auth0/auth0-react';
// import loadingGif from '../images/preloader.gif';
import { Loading } from '../../components';
function AuthWrapper({ children }) {
	const { isLoading, error } = useAuth0();
	if (isLoading) {
		return (
			<div className='wrapper'>
				<Loading />
			</div>
		);
	}
	if (error) {
		return (
			<div className='wrapper'>
				<h1>{error.message}</h1>
			</div>
		);
	}
	return <>{children}</>;
}

// const Wrapper = styled.section`
// 	min-height: 100vh;
// 	display: grid;
// 	place-items: center;
// 	img {
// 		width: 150px;
// 	}
// `;

export default AuthWrapper;
