import './single.scss';
import Navbar from '../../components/navbar/Navbar';
import List from '../../components/table/Table';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Single = () => {
	const TypesContrat = {
		1: 'Interim',
		2: 'CDD',
		3: 'CDI',
		4: 'CDII',
	};
	const [mission, setMission] = useState('');
	const [recommandations, setRecommandations] = useState('');
	const { missionId } = useParams();
	useEffect(() => {
		(async () => {
			const res = await axios.get(
				`https://api.intra.taman.fr/dashboard/mission/${missionId}`
			);
			const data = res.data;

			setMission(data.mission);
			setRecommandations(data.recommandations);
		})();
	}, [missionId]);

	mission && (
		<section className='single'>
			{/* <Sidebar /> */}
			<div className='singleContainer'>
				<Navbar />
				<h1 className='text-center'>Informations Mission</h1>
				<div className='top'>
					<div className='left'>
						<h3 className='title'>Informations Client</h3>
						<div className='item'>
							<div className='row gx-0 d-flex justify-content-center w-100 '>
								<div className='details'>
									<div className='detailItem'>
										<span className='itemKey'>ID:</span>
										<span className='itemValue'>
											{mission.client.id}
										</span>
									</div>

									<div className='detailItem'>
										<span className='itemKey'>Client:</span>
										<br />
										<span className='itemValue'>
											{mission.client.name}
										</span>
									</div>

									<div className='detailItem'>
										<span className='itemKey'>Phone:</span>
										<br />
										<span className='itemValue'>
											{mission.client.telephone ||
												'(Non renseigné)'}
										</span>
									</div>

									<div className='detailItem'>
										<span className='itemKey'>
											Addresse:
										</span>
										<br />
										<span className='itemValue'>
											{mission.client.adresse}
										</span>
									</div>
									<div className='detailItem'>
										<span className='itemKey'>
											Code postal:
										</span>
										<br />
										<span className='itemValue'>
											{mission.client.codePostal}
										</span>
									</div>
								</div>
								<div className='details'></div>
							</div>
						</div>
					</div>
					<div className='right'>
						<div className='item'>
							<div className='details'>
								<h3 className='itemTitle text-center'>
									{mission.denominationMetierClient}
								</h3>

								<div className='row gx-0 d-flex justify-content-center w-100'>
									<div className='col'>
										<div className='detailItem'>
											<span className='itemKey'>
												Addresse:
											</span>
											<br />
											<span className='itemValue'>
												{mission.adresse}
											</span>
										</div>
										<div className='detailItem'>
											<span className='itemKey'>
												Code postal:
											</span>
											<br />
											<span className='itemValue'>
												{mission.codePostal}
											</span>
										</div>
										<div className='detailItem col'>
											<span className='itemKey'>
												Début:
											</span>
											<br />
											<span className='itemValue'>
												{mission.date_debut &&
													new Date(
														mission.date_debut
													).toLocaleDateString()}
											</span>
										</div>
										<div className='detailItem col'>
											<span className='itemKey'>
												Fin:
											</span>
											<br />
											<span className='itemValue'>
												{mission.date_fin &&
													new Date(
														mission.date_fin
													).toLocaleDateString()}
											</span>
										</div>
									</div>
									<div className='col'>
										<div className='detailItem'>
											<span className='itemKey'>
												Type de contrat:
											</span>

											<span className='itemValue'>
												{
													TypesContrat[
														mission.id_typeContrat
													]
												}
											</span>
										</div>
										<div className='detailItem'>
											<span className='itemKey'>
												Besoins:
											</span>

											<span className='itemValue'>
												{mission.nbInterimaires}
											</span>
										</div>
										<div className='detailItem'>
											<span className='itemKey'>
												Coefficient Horaire:
											</span>

											<span className='itemValue'>
												{`${mission.CoefficientHoraire} %`}
											</span>
										</div>
										<div className='detailItem'>
											<span className='itemKey'>
												Taux Horaire:
											</span>

											<span className='itemValue'>
												{`${mission.TauxHorairePaye} %`}
											</span>
										</div>
										<div className='detailItem col'>
											<span className='itemKey'>
												Début:
											</span>

											<span className='itemValue'>
												{mission.date_debut &&
													new Date(
														mission.date_debut
													).toLocaleDateString()}
											</span>
										</div>
										<div className='detailItem col'>
											<span className='itemKey'>
												Fin:
											</span>

											<span className='itemValue'>
												{mission.date_fin &&
													new Date(
														mission.date_fin
													).toLocaleDateString()}
											</span>
										</div>
										<div className='detailItem col'>
											<span className='itemKey'>
												Chiffre d'affaire:
											</span>

											<span className='itemValue'>
												<span className='badge badgeCa'>
													{mission.ca
														? `${mission.ca} €`
														: 'Informations manquantes'}
												</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='bottom'>
					<h1 className='title'>Recommandations</h1>
					<List recommandations={recommandations} />
				</div>
			</div>
		</section>
	);
};

export default Single;
