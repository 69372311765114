import './widgetTmn.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import SendIcon from '@mui/icons-material/Send';
import NumbersIcon from '@mui/icons-material/Numbers';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

const WidgetTmn = ({ type, title, amount = 0, diff = 0, missions = [] }) => {
	let data = {};
	let num = 0;
	const [ca, setCa] = useState(0);

	const showSingle = (e, id) => {
		e.preventDefault();
		Navigate(`mission/${id}`);
	};

	switch (type) {
		case 'chiffreAffaires':
			data = {
				title: title,
				isMoney: true,
				link: '',
				icon: (
					<EuroSymbolIcon
						className='icon'
						style={{
							color: 'rgba(243,82,56)',
							backgroundColor: 'rgba(243,82,56,0.2)',
						}}
					/>
				),
			};
			break;
		case 'chiffreAffairesPotentiel':
			data = {
				title: title,
				isMoney: true,
				// link: '',
				icon: (
					<ShoppingCartIcon
						className='icon'
						style={{
							color: 'slateblue',
							backgroundColor: 'rgba(106,90,205,0.2)',
						}}
					/>
				),
			};
			break;
		case 'cvEnvoyes':
			data = {
				title: 'CV ENVOYES',
				isMoney: false,
				// link: 'Voir tous les besoins',
				icon: (
					<SendIcon
						className='icon'
						style={{
							color: '#039be5',
							backgroundColor: 'rgba(3, 155, 229,0.2)',
						}}
					/>
				),
			};
			break;
		case 'onBoardings':
			data = {
				title: 'ONBOARDINGS',
				isMoney: false,
				// link: 'Voir les onboardings',
				icon: (
					<PersonIcon
						className='icon'
						style={{
							color: 'green',
							backgroundColor: 'rgba(0,128,0,0.2)',
						}}
					/>
				),
			};
			break;
		case 'nbMissions':
			data = {
				title: title,
				isMoney: false,
				// link: '',
				icon: (
					<NumbersIcon
						className='icon'
						style={{
							color: 'green',
							backgroundColor: 'rgba(0,128,0,0.2)',
						}}
					/>
				),
			};
			break;
		case 'besoinsClients':
			data = {
				title: 'BESOINS CLIENTS',
				isMoney: false,
				// link: 'Voir tous les besoins',
				icon: (
					<ShoppingCartIcon
						className='icon'
						style={{
							color: 'goldenrod',
							backgroundColor: 'rgba(218,165,32,0.2)',
						}}
					/>
				),
			};
			break;
		default:
			break;
	}

	return (
		<div className='widget'>
			<div className='left'>
				<span className='title'>{data.title}</span>
				<span className='counter'>
					{amount.toLocaleString('fr-FR')}
					{data.isMoney && ' €'}
				</span>
				<span className='link'>{data.link}</span>
			</div>
			<div className='right'>
				<div className='percentage positive'>
					{/* {diff ? (
						<>
							<KeyboardArrowUpIcon />
							{diff}%
						</>
					) : (
						'--'
					)} */}
				</div>
				{data.icon}
			</div>
		</div>
	);
};

WidgetTmn.defaultProps = {
	amount: 0,
};

export default WidgetTmn;
