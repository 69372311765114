import './tmn.scss';

import {
	Navbar,
	Querier,
	Featured,
	WidgetTmn,
	Datatable,
} from '../../components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from '../../components/chart/Chart';
import { Loading } from '../../components';
import TxTableTmn from '../../components/txTableTmn/TxTableTmn';

const Tmn = () => {
	const [data, setData] = useState([]);
	const [chartsData, setChartsData] = useState([]);
	const [isLoading, setIsloading] = useState(false);

	useEffect(() => {
		try {
			(async () => {
				const res1 = await axios.get(
					'https://api.intra.taman.fr/dashboard/users'
				);
				const res2 = await axios.get(
					'https://api.intra.taman.fr/dashboard/clients'
				);
				const users = res1.data;
				const clients = res2.data;
				setData({ clients, users });
			})();
		} catch (error) {
			console.log(error);
		}
	}, []);

	const sendForm = async (...args) => {
		setIsloading(true);

		const params = args[0];
		try {
			const res = await axios.post(
				`https://api.intra.taman.fr/dashboard/formReq`,
				params,
				{
					headers: {
						// 'X-Requested-With': 'XMLHttpRequest',
						'Content-type': 'application/x-www-form-urlencoded',
					},
				}
			);

			setChartsData(res.data);
			setIsloading(false);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<section className='tmn'>
			<div className='tmnContainer'>
				<Navbar />
				<div className='row gx-0 d-flex'>
					<section className='querrier'>
						<Querier sendForm={sendForm} data={data} />
					</section>
				</div>
				{isLoading ? (
					<Loading />
				) : (
					<div className='row gx-0 h-100 d-flex'>
						{chartsData.missions && (
							<>
								<section className='tableTx'>
									<TxTableTmn
										missions={Object.values(
											chartsData.missions
										).map(mission => mission._data)}
										recruteurs={data.users}
										totalBesoins={chartsData.nbBesoinClient}
										totalCvEnvoyes={chartsData.nbCvEnvoye}
										totalOnboardings={
											chartsData.nbOnBoardings
										}
									/>
								</section>
								<section className='recharts'>
									{/* <TxTableTmn
        recruteurs={users}
        missions={Object.values(chartsData.missions).map(
            mission => mission._data
        )}
    /> */}
									{/* <div className='d-flex section-ca'>
										{chartsData.missions && (
											<WidgetTmn
												type='chiffreAffaires'
												title="Chiffre d'affaire prévisionnel (onboardings réalisés)"
												amount={
													chartsData.chiffreAffaireTotal
												}
											/>
										)}
									</div> */}
									<div className='widgets'>
										<WidgetTmn
											type='besoinsClients'
											amount={chartsData.nbBesoinClient}
										/>
										<WidgetTmn
											type='cvEnvoyes'
											amount={chartsData.nbCvEnvoye}
										/>
										<WidgetTmn
											type='onBoardings'
											amount={chartsData.nbOnBoardings}
										/>
									</div>
									<div className='listContainer'>
										<h3 className='listTitle ms-3'>
											{chartsData.missions && (
												<span>
													Missions(
													{
														Object.values(
															chartsData.missions
														).length
													}
													)
												</span>
											)}
										</h3>
										{data.users &&
											data.clients &&
											chartsData.missions && (
												<Datatable
													type='mission'
													users={data.users}
													clients={data.clients}
													missions={
														chartsData.missions
													}
												/>
											)}
									</div>
								</section>
							</>
						)}
					</div>
				)}
			</div>
		</section>
	);
};

export default Tmn;
