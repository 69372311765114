import './querier.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Chart from '../chartTmn/Chart';

const Querier = ({ data, sendForm }) => {
	const [inputs, setInputs] = useState([]);
	const [checkedBox, setCheckedBox] = useState(true);

	const handleSubmit = e => {
		e.preventDefault();
		localStorage.setItem('inputs', JSON.stringify(inputs));
		sendForm(inputs);
	};

	useEffect(() => {
		const saved_inputs = JSON.parse(localStorage.getItem('inputs'));
		if (saved_inputs) {
			setInputs(() => saved_inputs);
		}
	}, []);

	const handleChange = event => {
		const name = event.target.name;

		const value =
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value;

		setInputs(values => ({ ...values, [name]: value }));
	};
	return (
		<div id='filters' className='pt-2'>
			<form className='form row align-items-end' onSubmit={handleSubmit}>
				<div className='form-input col-xl-2 col-6'>
					<label className='form-label' htmlFor='date_debut_min'>
						Date de Début {'>='} à
					</label>
					<input
						className='form-control'
						type='date'
						onChange={handleChange}
						name='date_debut_min'
						value={inputs.date_debut_min || ''}
					/>
				</div>
				<div className='form-input col-xl-2 col-6'>
					<label className='form-label' htmlFor='date_debut_max'>
						Date de Début {'<='} à
					</label>
					<input
						className='form-control'
						type='date'
						onChange={handleChange}
						name='date_debut_max'
						value={inputs.date_debut_max || ''}
					/>
				</div>
				<div className='form-input col-xl-2 col-6'>
					<label htmlFor='client' className='form-label'>
						Client
					</label>
					<select
						className='form-control'
						name='client'
						id='client'
						onChange={handleChange}
						value={inputs.client}>
						<option value=''></option>
						{data.clients &&
							Object.entries(data.clients).map((c, index) => (
								<option key={index} value={c[1].id}>
									{c[1].name}
								</option>
							))}
					</select>
				</div>
				<div className='form-input col-xl-2 col-6 userFilter'>
					<label htmlFor='user' className='form-label'>
						Commercial
					</label>

					<select
						id='user'
						className='form-control'
						onChange={handleChange}
						name='user'
						value={inputs.user}>
						<option value=''></option>
						{data.users &&
							Object.entries(data.users).map((u, index) => (
								<option key={index} value={u[1]._data.id}>
									{u[1]._data.prenom} {u[1]._data.nom}
								</option>
							))}
					</select>
				</div>

				<div className='form-check-inline col-auto mb-2 d-flex'>
					<input
						className='form-check-input me-3'
						type='checkbox'
						id='hide_archived'
						onChange={handleChange}
						name='hide_archived'
					/>
					<label className='form-check-label' htmlFor='hide_archived'>
						Masquer les missions archivées
					</label>
				</div>
				<div className='form-submit form-input col-3 col-lg-1 mt-4'>
					<button className='btn btn-primary btn-lg btnSendQuery'>
						Valider
					</button>
				</div>
			</form>
		</div>
	);
};

export default Querier;
