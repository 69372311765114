import './navbar.scss';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const Navbar = () => {
	// const { dispatch } = useContext(DarkModeContext);
	const { isAuthenticated, loginWithRedirect, logout, isLoading, user } =
		useAuth0();
	const isUser = isAuthenticated && user;

	return (
		<div className='navbar px-5'>
			<div className='wrapper'>
				{isUser && user.name && (
					<h3 className='ms-auto '>{user.nickname}</h3>
				)}
				{isUser ? (
					<button
						className='btn btn-secondary ms-5'
						onClick={() => {
							logout({ returnTo: window.location.origin });
						}}>
						Déconnexion
					</button>
				) : (
					<button
						className='btn btn-primary ms-2'
						onClick={loginWithRedirect}>
						Connexion
					</button>
				)}
			</div>
		</div>
	);
};

export default Navbar;
