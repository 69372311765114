import { Link } from 'react-router-dom';
import './error.scss';

const Error = ({ error_message }) => {
    return (
        <div className='error'>
            <div className='errorMsg'>
                <h1>{error_message || 'Erreur 404'}</h1>
                <p>Page introuvable</p>
            </div>
            <Link to='/' style={{ textDecoration: 'none' }}>
                <span className='link_home'>Retour à l'accueil</span>
            </Link>
        </div>
    );
};

export default Error;
