import './featuredTmn.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from 'react';
const Featured = ({
	cvEnvoyes = '',
	onBoardings = '',
	besoinsClients = '',
	txProductivite,
	txTransformation,
	txService,
	title = '',
	subTitle,
	objectif,
}) => {
	const [tx, setTx] = useState(0);
	const [color, setColor] = useState('');
	useEffect(() => {
		if (txProductivite) setTx(txProductivite);
		if (txTransformation) setTx(txTransformation);
		if (txService) setTx(txService);
	}, [
		besoinsClients,
		cvEnvoyes,
		onBoardings,
		txProductivite,
		txTransformation,
		txService,
	]);

	useEffect(() => {
		if (!isNaN(tx)) {
			if (tx < objectif - 40) {
				setColor('#FF0000');
			} else if (tx >= objectif - 40 && tx < objectif - objectif * 0.1) {
				setColor('#FF8C00');
			} else if (tx >= objectif - objectif * 0.1) {
				setColor('#228B22');
			}
		} else {
			setColor('#000');
		}
	}, [tx]);

	return (
		<div className='featured p-3 mb-3'>
			<div className='top'>
				<div className='d-flex'>
					<h1 className='title'>{title}</h1>
				</div>
				<p className='subtitle'>{subTitle}</p>
			</div>
			<div className='bottom'>
				<div className='featuredChart'>
					<CircularProgressbar
						value={tx}
						text={`${tx}%`}
						styles={buildStyles({
							// Rotation of path and trail, in number of turns (0-1)
							rotation: 1,
							// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
							strokeLinecap: 'round',
							// Text size
							textSize: '16px',
							// How long animation takes to go from one percentage to another, in seconds
							pathTransitionDuration: 0.5,
							// Can specify path transition in more detail, or remove it entirely
							// pathTransition: 'none',

							// Colors
							pathColor: `${color}`,
							textColor: `${color}`,
							trailColor: '#d6d6d6',
						})}
					/>
				</div>
			</div>
		</div>
	);
};

export default Featured;
