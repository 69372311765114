import { DataGrid } from '@mui/x-data-grid';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PauseIcon from '@mui/icons-material/Pause';
import './datatableTmn.scss';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function DataTableTmn({
	missions,
	clients,
	users,
	type = 'mission',
}) {
	let navigate = useNavigate();
	const statutsMission = {
		1: 'brouillon',
		2: 'pré-commande',
		3: 'commande',
		4: 'sourcing',
		5: 'on Boarding',
		6: 'archivé',
	};
	const contratsMission = {
		1: 'interim',
		2: 'cdd',
		3: 'cdi',
		4: 'cdii',
	};

	const [dataRows, setDataRows] = useState([]);
	const [dataColumns, setDataColumns] = useState([]);
	const actionColumn = [
		{
			field: 'action',
			headerName: 'Action',
			width: 140,
			renderCell: params => {
				return (
					<div className='cellAction ms-3'>
						<div
							className='viewButton'
							onClick={e => showSingle(e, params.row.id)}>
							voir
						</div>
					</div>
				);
			},
		},
	];
	const getData = () => {
		let arr = [];
		switch (type) {
			case 'mission':
				for (const mission of Object.entries(missions)) {
					mission[1]._data.statut =
						statutsMission[mission[1]._data.id_missionStatut];
					mission[1]._data.typeContrat =
						contratsMission[mission[1]._data.id_typeContrat];
					arr = [...arr, mission[1]._data];
				}
				let missionColumns = [
					{
						field: 'id',
						headerName: 'ID',
						width: 70,
						renderCell: params => {
							return (
								<div className='idMission'>
									{params.row.id}

									{params.row.id_importance == 2 ? (
										<PriorityHighIcon className='mission_importante' />
									) : params.row.id_importance == 3 ? (
										<PauseIcon className='mission_standby' />
									) : (
										''
									)}
								</div>
							);
						},
					},
					{
						field: 'typeContrat',
						headerName: 'Type de contrat',
						width: 100,
						renderCell: params => {
							return (
								<div className='typeContrat'>
									{params.row.typeContrat}
								</div>
							);
						},
					},
					{
						field: 'statut',
						headerName: 'Statut Mission',
						width: 130,
						renderCell: params => {
							return (
								<div className='missionsStatut'>
									<span
										style={{
											textAlign: 'center',
										}}>
										{params.row.statut}
									</span>
								</div>
							);
						},
					},
					{
						field: 'txtTitre',
						headerName: 'Titre',
						width: 300,
					},
					{
						field: 'ca',
						headerName: 'CA',
						width: 110,
						renderCell: params => {
							return params.row.ca ? (
								<div className='clientCell badge bgColor1Taman'>
									{`${params.row.ca} €`}
								</div>
							) : (
								'...'
							);
						},
					},
					{
						field: 'nbBesoinsClient',
						headerName: 'Besoins client',
						width: 110,
						renderCell: params => {
							return (
								<div className='besoinsClientCell'>
									{params.row.nbInterimaires}
								</div>
							);
						},
					},
					{
						field: 'nbCvEnvoye',
						headerName: 'Cv envoyés',
						width: 100,
						renderCell: params => {
							return (
								<div className='clientCell'>
									{params.row.nbCvEnvoye}
								</div>
							);
						},
					},
					{
						field: 'nbOnBoarding',
						headerName: 'Onboardings',
						width: 100,
						renderCell: params => {
							return (
								<div className=''>
									{params.row.nbOnBoardings}
								</div>
							);
						},
					},
					{
						field: 'date_debut',
						headerName: 'Date de début',
						width: 130,
					},
					{
						field: 'date_fin',
						headerName: 'Date de fin',
						width: 130,
					},
				];
				const clientColumns = [
					{
						field: 'client',
						headerName: 'Client',
						width: 140,
						renderCell: params => {
							return (
								<div className='clientCell'>
									{clients[params.row.id_client]?.name}
								</div>
							);
						},
					},
				];
				missionColumns = [
					...missionColumns,
					...clientColumns,
					...actionColumn,
				];
				return { rows: arr, colonnes: missionColumns };

				break;
			case 'user':
				for (const user of Object.entries(users)) {
					arr = [...arr, user[1]._data];
				}
				const userColumn = [
					{ field: 'id', headerName: 'ID', width: 70 },
					{
						field: 'prenom',
						headerName: 'Prénom',
						width: 300,
					},
					{
						field: 'nom',
						headerName: 'Nom',
						width: 300,
					},
					{
						field: 'email',
						headerName: 'Email',
						width: 170,
					},
				];
				return { rows: arr, colonnes: userColumn };
			default:
				break;
		}
		return arr;
	};
	useEffect(() => {
		const { rows, colonnes } = getData();

		setDataRows(rows);
		setDataColumns(colonnes);
	}, []);

	const missionRows = setRows();

	function missionExists(arr, missionId) {
		return arr.some(function (el) {
			return el.id === missionId;
		});
	}
	function setRows() {
		const rows = [];
		for (const mission of Object.entries(missions)) {
			if (!missionExists(rows, mission[1]._data)) {
				const fMission = {};
				fMission.id = mission[1]._data.id;
				fMission.txtTitre = mission[1]._data.txtTitre;
				fMission.txtCA = mission[1]._data.ca || 0;
				fMission.nbCvEnvoye = mission[1]._data.nbCvEnvoye;
				fMission.nbOnBoarding = mission[1]._data.nbOnBoarding;
				fMission.date_debut = mission[1]._data.date_debut;
				fMission.date_fin = mission[1]._data.date_fin;
				fMission.client = clients[mission[1]._data.id_client]?.name;

				rows.push(fMission);
			}
		}
		return rows;
	}
	if (type === 'mission') {
	}

	function showSingle(e, id) {
		e.preventDefault();
		navigate(`mission/${id}`);
	}
	const [data, setData] = useState(dataRows);

	return (
		<div className='datatable'>
			<DataGrid
				className='datagrid'
				rows={dataRows}
				columns={dataColumns}
				pageSize={15}
				rowsPerPageOptions={[15]}
			/>
		</div>
	);
}
