import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login, Single, Tmn, Error, AuthWrapper } from './pages';
import './style/dark.scss';
import { PrivateRoute } from './pages/privateRoute/PrivateRoute';
function App() {
	return (
		// <div className={`app ${styleMode}`}>
		<AuthWrapper>
			<div className={`app`}>
				<BrowserRouter basename='/'>
					<Routes>
						<Route path='/'>
							<Route>
								<Route
									index
									element={
										<PrivateRoute>
											<Tmn />
										</PrivateRoute>
									}
								/>
							</Route>
							<Route path='mission'>
								<Route
									path=':missionId'
									element={
										<PrivateRoute>
											<Single />
										</PrivateRoute>
									}
								/>
							</Route>
							<Route path='login' element={<Login />} />
							<Route path='*' element={<Error />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</div>
		</AuthWrapper>
	);
}

export default App;
