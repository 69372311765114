import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { DarkModeContextProvider } from './context/darkModeContext';
// import store from './app/store';
// import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Auth0Provider
			domain='dev-mzw2v7pkic7r5iz2.eu.auth0.com'
			clientId='I777BndImEJYLciNN88aQaKdrY0EnhXW'
			redirectUri={window.location.origin}>
			{/* <Provider store={store}> */}
			{/* <DarkModeContextProvider> */}
			<App />
			{/* </DarkModeContextProvider> */}
			{/* </Provider> */}
		</Auth0Provider>
	</React.StrictMode>
);
